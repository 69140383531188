<script>
  // CommitChart.js
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  // mixins: [reactiveProp],
  props: ['data'],
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
      }
    }
  }),

  mounted () {
    this.renderChart(this.data,this.options)
  }
}
</script>