<script>
  // CommitChart.js
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: ['options'],
  // data() {
  //   return {
  //     chartdata: {
  //       labels: [
  //             "Frühdienst",
  //             "Tagesdienst",
  //             "Mitteldienst",
  //             "Spätdienst",
  //             "Nachtdienst",
  //           ],
  //       datasets: [
  //         {
  //           backgroundColor: ["blue", "green", "red", "orange", "yellow"],
  //           data: this.d
  //         }
  //       ],
  //     },
  //     options: {
  //       responsive: true,
  //       maintainAspectRatio: false
  //     }
  //   }
  // },

  mounted () {
    // this.renderChart(this.chartdata, this.options)
    this.renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false
      })
  }
}
</script>