<template>
  <div id="Statistic" v-if="!isLoading">
    <!-- <div>
      <h1 class="brand-color-dark">Statistik</h1>
    </div> -->

    <div class="lg:sticky lg:top-16 relative top-0 border-b-4 border-blue-700 rounded-md grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-2 justify-between items-center px-3 py-3 bg-gray-800 my-3">
      <div><h1 class="brand-color-dark text-center sm:text-left">Statistik</h1></div>
      <div class="flex items-center justify-between gap-2"><button @click="calendarLeft"><font-awesome-icon icon="chevron-left" class="icon" /></button><button  @click="calendarToday" class="text-lg">{{getMonthLabel(shiftViewerCalendar.month)}} {{shiftViewerCalendar.year}}</button><button @click="calendarRight"><font-awesome-icon icon="chevron-right" class="icon" /></button></div>
    </div>

    <div class="inline-block lg:hidden border-2 border-blue-700 fixed bottom-3 rounded-md px-3 py-3 bg-gray-800 ">
      <div class="flex items-center justify-between gap-2"><button @click="calendarLeft"><font-awesome-icon icon="chevron-left" class="icon" /></button><button  @click="calendarToday" class="text-lg">{{getMonthLabel(shiftViewerCalendar.month)}} {{shiftViewerCalendar.year}}</button><button @click="calendarRight"><font-awesome-icon icon="chevron-right" class="icon" /></button></div>
    </div>

    <div class="flexbox">
      <div class="box bg-card-content">
        <h1 class="">Datumsbereich</h1>
        <p class="text-center text-xl py-5">{{getMonthLabel(shiftViewerCalendar.month)}} {{shiftViewerCalendar.year}}</p>
        <p class="text-center">
          Aktuell ausgewählter Datumsbereich: {{getFormatedDate(new Date(shiftViewerCalendar.year, shiftViewerCalendar.month, 1))}} bis {{getFormatedDate(new Date(shiftViewerCalendar.year, shiftViewerCalendar.month + 1, 0))}}. 
        </p>
      </div>
      <div class="box bg-card-content">
        <h1 class="">Diensttage</h1>
        <p class="text-center text-xl py-5">{{shifts.length}}</p>
        <p class="text-center">
          Wir haben {{shifts.length}} von {{getDaysInMonth()}} Tage für den ausgewählten Monat erfasst. 
        </p>
      </div>
    </div>

    <div class="flexbox">
      <div class="box bg-card-content">
        <h1 class="">Linien</h1>
          <div class="card-box">
            <div class="exchange-details" v-for="(s, i) in statsLines" :key="i">
              <div class="title my-auto">{{ s[0] }}</div>
              <div class="badge badge-white my-auto">{{ s[1] }}</div>
            </div>

            <div
              class="notification"
              v-if="Object.keys(statsLines).length === 0"
            >
              <p>Es gibt zurzeit nicht ausreichend Informationen.</p>
            </div>
          </div>
      </div>
      <div class="box bg-card-content">
        <h1 class="">Pausenorte</h1>
          <div class="card-box">
            <div
              class="exchange-details"
              v-for="(s, i) in statsBreakLocations"
              :key="i"
            >
              <div class="title my-auto">{{ s[0] }}</div>
              <div class="badge badge-white my-auto">{{ s[1] }}</div>
            </div>
            <div
              class="notification"
              v-if="Object.keys(statsBreakLocations).length === 0"
            >
              <p>Es gibt zurzeit nicht ausreichend Informationen.</p>
            </div>
          </div>
      </div>
    </div>
    <div class="flexbox">
      <div class="box bg-card-content">
        <h1 class="">Häufigste Linie</h1>
        <div
          class="notification"
          v-if="Object.keys(statsLines).length === 0"
        >
          <p>Es gibt zurzeit nicht ausreichend Informationen.</p>
        </div>
        <div v-else>
          <ChartTest class="chart" :chart-data="statsGraphLines" :key="componentKey" />
          <p class="text-center text-xl py-5">
            <!-- <font-awesome-icon icon="arrow-up" class="icon text-green-600" /> --> {{statsLines[0][1]}} Mal<br>
            <span class="text-2xl">Linie {{statsLines[0][0]}}</span>
          </p>
          <p class="text-center">
            Du hast diesen Monat am häufigsten die Linie <b>{{statsLines[0][0]}}</b> mit <b>{{statsLines[0][1]}} Mal</b>.
            <!-- Das ist <b>3 Mal</b> <b>mehr</b>, als im vorherigen Monat. -->
          </p>
        </div>
      </div>
      <div class="box bg-card-content">
        <h1 class="">Krankentage</h1>
        <ChartTest class="chart" :chart-data="statsGraphSick" :key="componentKey" />
        <p class="text-center text-xl py-5">
          <!-- <font-awesome-icon icon="circle" class="icon text-gray-400" /> --> {{statsSick}} Mal 
        </p>
        <p class="text-center">
          Du hast Dich diesen Monat <b>{{statsSick}} Tag(e)</b> krank gemeldet. 
          <!-- Insgesamt warst Du dieses Jahr bisher <b>3 Tage</b> krank gemeldet. -->
        </p>
      </div>
    </div>

    <div class="flexbox">
      <div class="box bg-card-content">
        <h1 class="">Dienstlagen</h1>
        <div
          class="notification"
          v-if="Object.keys(statsServiceSituations).length === 0"
        >
          <p>Es gibt zurzeit nicht ausreichend Informationen.</p>
        </div>
        <div v-else>
          <ChartTest class="chart" :chart-data="statsGraphServiceSituations" :key="componentKey" />
          <p class="text-center text-xl py-5">
            <!-- <font-awesome-icon icon="arrow-up" class="icon text-green-600" /> --> {{statsServiceSituations[0].count}} Mal<br>
            <span class="text-2xl"><span v-html="statsServiceSituations[0].text"></span></span>
          </p>
          <!-- <p class="text-center">
            Du hast diesen Monat <b>mehr</b> <b>Mitteldienste</b> als im
            vorherigen Monat.
          </p> -->
        </div>
      </div>
      <div class="box bg-card-content">
        <h1 class="">Pausenorte</h1>
        <div
          class="notification"
          v-if="Object.keys(statsBreakLocations).length === 0"
        >
          <p>Es gibt zurzeit nicht ausreichend Informationen.</p>
        </div>

        <div v-else>
          <!-- <ChartTest class="chart" /> -->
          <ChartTest class="chart" :chart-data="statsGraphBreakLocations" :key="componentKey" />
          <p class="text-center text-xl py-5">
            <!-- <font-awesome-icon icon="arrow-up" class="icon text-green-600" /> --> {{statsBreakLocations[0][1]}} Mal<br>
            <span class="text-2xl">{{statsBreakLocations[0][0]}}</span>
          </p>
          <!-- <p class="text-center">
            Du hast diesen Monat <b>gleich oft</b> den Pausenort <b>RDP</b> wie im
            vorherigen Monat.
          </p> -->
        </div>
      </div>
    </div>

    <div class="flexbox">
      <div class="box bg-card-content">
        <h1 class="">Dienstbeginn</h1>

        <div
          class="notification"
          v-if="Object.keys(statsStartLocations).length === 0"
        >
          <p>Es gibt zurzeit nicht ausreichend Informationen.</p>
        </div>

        <div v-else>
          <ChartTest class="chart" :chart-data="statsGraphStartLocations" :options="graphOptions" :key="componentKey" />
          <p class="text-center text-xl py-5">
            <!-- <font-awesome-icon icon="arrow-up" class="icon text-green-600" /> --> {{statsStartLocations[0][1]}} Mal<br>
            <span class="text-2xl">{{statsStartLocations[0][0]}}</span>
          </p>
        </div>
      </div>
      <div class="box bg-card-content">
        <h1 class="">Dienstende</h1>

        <div
          class="notification"
          v-if="Object.keys(statsEndLocations).length === 0"
        >
          <p>Es gibt zurzeit nicht ausreichend Informationen.</p>
        </div>

        <div v-else>
          <ChartTest class="chart" :chart-data="statsGraphEndLocations" :key="componentKey" />
          <p class="text-center text-xl py-5">
            <!-- <font-awesome-icon icon="arrow-up" class="icon text-green-600" /> --> {{statsEndLocations[0][1]}} Mal<br>
            <span class="text-2xl">{{statsEndLocations[0][0]}}</span>
          </p>
        </div>

<!--         
        <p class="text-center text-xl py-5">
          <font-awesome-icon icon="arrow-up" class="icon text-green-600" /> 4 Mal<br>
          <span class="text-2xl">Depot MRH</span>
        </p>
        <p class="text-center">
          Feierabend hast Du diesen Monat am häufigsten im <b>Depot MRH</b>.
        </p> -->
      </div>
    </div>

    <div class="box my-3 bg-card-content">
      <h1 class="">Arbeitszeiten</h1>
      <div v-if="isStatsWorkingTimeReady && statsWorkingTime.length > 0">
        <ChartBar class="chart" :data="statsGraphWorkingTime" :key="componentKey" />
        <p class="text-center">
          Deine längste Dienstzeit hast Du am <b>{{getWeekdayForDate(this.statsWorkingTime[this.statsWorkingTime.length - 1].s.date)}}, den {{getFormatedDate(this.statsWorkingTime[this.statsWorkingTime.length - 1].s.date)}}</b> mit
          <b>{{this.statsWorkingTime[this.statsWorkingTime.length - 1].s.shift_workingtime}} Stunden</b>.<br>Deinen kürzesten Dienst hast Du am
          <b>{{getWeekdayForDate(this.statsWorkingTime[0].s.date)}}, den {{getFormatedDate(this.statsWorkingTime[0].s.date)}}</b> mit <b>{{this.statsWorkingTime[0].s.shift_workingtime}} Stunden</b>.
        </p>
      </div>
      <p v-else>Es gibt zurzeit nicht ausreichend Informationen.</p>
    </div>

    <div class="box my-3 bg-card-content">
      <h1 class="">Übergangszeiten</h1>
      <div  v-if="isStatsTransitionalPeriodsReady && statsTransitionalPeriods.length > 0">
        <ChartLine class="chart" :data="statsGraphTransitionalPeriods" :key="componentKey" />
        <p class="text-center">
          Deine längste Übergangszeit hast Du am <b>{{getWeekdayForDate(this.statsTransitionalPeriods[this.statsTransitionalPeriods.length - 1].s.date)}}, den {{getFormatedDate(this.statsTransitionalPeriods[this.statsTransitionalPeriods.length - 1].s.date)}}</b> mit
          <b>{{this.statsTransitionalPeriods[this.statsTransitionalPeriods.length - 1].v}} Stunden</b>.<br>Deine kürzeste Übergangszeit hast Du am
          <b>{{getWeekdayForDate(this.statsTransitionalPeriods[0].s.date)}}, den {{getFormatedDate(this.statsTransitionalPeriods[0].s.date)}}</b> mit <b>{{this.statsTransitionalPeriods[0].v}} Stunden</b>.<br>
          <small class="input-helper-text-lightbg">Freie aufeinanderfolgende Tage beeinflussen die Berechnung der Übergangszeit.</small>
        </p>
      </div>
      <p v-else>Es gibt zurzeit nicht ausreichend Informationen.</p>
    </div>
  </div>
</template>




<script>
import ChartTest from "@/components/ChartPieWorkingShift";
import ChartLine from "@/components/ChartLineWorkingTime";
import ChartBar from "@/components/ChartBar";

import { mapGetters } from "vuex";

export default {
  name: "Statistic",
  components: {
    ChartTest,
    ChartLine,
    ChartBar
  },
  props: {},
  data() {
    return {
      isLoading: false,
      componentKey: 0,
      statsWorkingTime: [],
      statsLines: {},
      statsBreakLocations: {},
      statsSick: 0,
      statsStartLocations: {},
      statsEndLocations: {},
      graphOptions: {
        options: {
          responsive: true,
          maintainAspectRatio: true
        }
      },
      statsServiceSituations: [
        {
          text: '<i class="fas fa-dove" v-b-tooltip.hover title="Frühdienst"></i> Frühdienste',
          count: 0,
        },
        {
          text: '<i class="fas fa-sun" v-b-tooltip.hover title="Tagesdienst"></i> Tagesdienste',
          count: 0,
        },
        {
          text: '<i class="fas fa-cloud" v-b-tooltip.hover title="Mitteldienst"></i> Mitteldienste',
          count: 0,
        },
        {
          text: '<i class="fas fa-cloud-moon" v-b-tooltip.hover title="Spätdienst"></i> Spätdienste',
          count: 0,
        },
        {
          text: '<i class="fas fa-moon" v-b-tooltip.hover title="Nachtdienst"></i> Nachtdienste',
          count: 0,
        },
      ],
      statsGraphSick: {
        labels: [
          "On fly",
          "Krank"
        ],
        datasets: 
        [
          {
            backgroundColor: ["#447E17", "#BD1F1F"],
            data: []
          }
        ],
      },
      statsGraphServiceSituations: {
        labels: [
          "Frühdienste",
          "Tagesdienste",
          "Mitteldienste",
          "Spätdienste",
          "Nachtdienste",
        ],
        datasets: 
        [
          {
            backgroundColor: ["#A157EA", "#82A3A1", "#D7CD36", "#68ccf7", "#BC7438"],
            data: []
          }
        ],
      },
      statsGraphBreakLocations: {
        labels: [
        ],
        datasets: 
        [
          {
            backgroundColor: ["#FFF7D6", "#845EC2", "#D65DB1", "#5CECBF", "#FF6F91", "#FF9671", "#FFC75F", "#008E9B", "#7BB6B3", "#D0A616", "#FFF7D6", "#845EC2", "#D65DB1", "#5CECBF", "#FF6F91", "#FF9671", "#FFC75F", "#008E9B", "#7BB6B3", "#D0A616"],
            data: []
          }
        ],
      },
      statsGraphStartLocations: {
        labels: [
        ],
        datasets: 
        [
          {
            backgroundColor: ["#FFF7D6", "#845EC2", "#D65DB1", "#5CECBF", "#FF6F91", "#FF9671", "#FFC75F", "#008E9B", "#7BB6B3", "#D0A616", "#FFF7D6", "#845EC2", "#D65DB1", "#5CECBF", "#FF6F91", "#FF9671", "#FFC75F", "#008E9B", "#7BB6B3", "#D0A616"],
            data: []
          }
        ],
      },
      statsGraphEndLocations: {
        labels: [
        ],
        datasets: 
        [
          {
            backgroundColor: ["#FFF7D6", "#845EC2", "#D65DB1", "#5CECBF", "#FF6F91", "#FF9671", "#FFC75F", "#008E9B", "#7BB6B3", "#D0A616", "#FFF7D6", "#845EC2", "#D65DB1", "#5CECBF", "#FF6F91", "#FF9671", "#FFC75F", "#008E9B", "#7BB6B3", "#D0A616"],
            data: []
          }
        ],
      },
      statsGraphLines: {
        labels: [
        ],
        datasets: 
        [
          {
            backgroundColor: ["#FFF7D6", "#845EC2", "#D65DB1", "#5CECBF", "#FF6F91", "#FF9671", "#FFC75F", "#008E9B", "#7BB6B3", "#D0A616", "#FFF7D6", "#845EC2", "#D65DB1", "#5CECBF", "#FF6F91", "#FF9671", "#FFC75F", "#008E9B", "#7BB6B3", "#D0A616"],
            data: []
          }
        ],
      },
      isStatsWorkingTimeReady: false,
      statsGraphWorkingTime: {
        labels: [],
        datasets: [
          {
            labels: 'WorkingTime',
            hideLabels: false,
            backgroundColor: '#17387E',
            borderColor: '#17387E',
            pointBackgroundColor: 'white',
            data: [],
            fill: false,
            tension: 0.1,
            pointRadius: 4,
            pointHitRadius: 7,
            pointHoverRadius: 9,
            spanGaps: false,

            // barPercentage: 0.5,
            // barThickness: 6,
            // maxBarThickness: 8,
            // minBarLength: 2,
          }
        ],
      },
      isStatsTransitionalPeriodsReady: false,
      statsGraphTransitionalPeriods: {
        labels: [],
        datasets: [
          {
            // labels: ["Hallo", "Welt", "!"],
            hideLabels: false,
            backgroundColor: '#17387E',
            borderColor: '#17387E',
            pointBackgroundColor: 'white',
            data: [],
            fill: false,
            tension: 0.1,
            pointRadius: 4,
            pointHitRadius: 7,
            pointHoverRadius: 9,
            spanGaps: false
          }
        ],
      },
    }
  },
  methods: {
    getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      },

    calendarLeft() {
      this.$store.dispatch("decrementShiftViewerCalendar").then((a) => {
      });
    },
    calendarRight() {
      this.$store.dispatch("incrementShiftViewerCalendar").then((a) => {
      });
    },
    calendarToday() {
      this.$store.dispatch("todayShiftViewerCalendar").then((a) => {
      });
    },

    createStatsTransitionalPeriods(shifts) {
      this.statsGraphTransitionalPeriods.datasets[0].data = []
      this.statsGraphTransitionalPeriods.labels = []

      this.statsTransitionalPeriods = [];

      // console.log("createStatsTransitionalPeriods------");

      for (let i = 0; i < shifts.length; i++) {

        if (shifts[i].shift.length == 0) {
          continue
        }

        var timeBetweenShifts = null

        var dataDate = new Date(shifts[i].date)
        var time = shifts[i].time_end.split(":");

        var time1 = time[1].includes("+");
        if (time1) {
          // console.log('time replace');
          time[1] = time[1].replace("+", "");
          dataDate.setDate(dataDate.getDate() + 1);
          dataDate.setHours(+time[0], +time[1]);
        } else {
          dataDate.setHours(+time[0], +time[1]);
        }

        var indexBindex = 0;
        for (let indexB = (i + 1); indexB < shifts.length; indexB++) {

          indexBindex = indexB;

          if (shifts[indexB].shift.length == 0) {
            continue
          }


          var dataDateEnd = new Date(shifts[indexB].date)
          var time = shifts[indexB].time_start.split(":");

          var time1 = time[1].includes("+");
          if (time1) {
            // console.log('time replace');
            time[1] = time[1].replace("+", "");
            dataDateEnd.setDate(dataDateEnd.getDate() + 1);
            dataDateEnd.setHours(+time[0], +time[1]);
          } else {
            dataDateEnd.setHours(+time[0], +time[1]);
          }

          var startDate = dataDate;
          var endDate   = dataDateEnd;
          timeBetweenShifts = ((endDate.getTime() - startDate.getTime()) / 1000 / 3600).toFixed(2);

          

          this.statsTransitionalPeriods.push({i: i, v: timeBetweenShifts, s: shifts[i]}) 

          break;

        }

        this.statsGraphTransitionalPeriods.labels.push((i + 1) + ".->" + (indexBindex + 1) + ".");
        this.statsGraphTransitionalPeriods.datasets[0].data.push(timeBetweenShifts);

        


        
        //i: index, v: value, d: date
      }

      // console.log(this.statsTransitionalPeriods)

      this.statsTransitionalPeriods.sort(function (a, b) {
        return a.v - b.v;
      });

      this.isStatsTransitionalPeriodsReady = true;

      // console.log("-----createStatsTransitionalPeriods");
    },

    createStatsWorkingTime(shift) {
      this.statsGraphWorkingTime.datasets[0].data = []
      this.statsGraphWorkingTime.labels = []

      this.statsWorkingTime = [];

      // console.log("createStatsWorkingTime------");

      for (let index = 0; index < shift.length; index++) {

        if (shift[index].shift_workingtime == null) {
          this.statsGraphWorkingTime.labels.push((index + 1) + ".");
          this.statsGraphWorkingTime.datasets[0].data.push(null);
          continue;
        }

        var hoursMinutes = shift[index].shift_workingtime.split(/[.:]/);
        var hours = parseInt(hoursMinutes[0], 10);
        var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        var a = (hours + minutes / 60).toFixed(2);

        this.statsGraphWorkingTime.labels.push((index + 1) + ".");
        this.statsGraphWorkingTime.datasets[0].data.push(a);

        this.statsWorkingTime.push({i: index, v: a, s: shift[index]}) 
        //i: index, v: value, d: date
      }

      // console.log(this.statsWorkingTime)

      this.statsWorkingTime.sort(function (a, b) {
        return a.v - b.v;
      });

      this.isStatsWorkingTimeReady = true;

      // console.log("-----createStatsWorkingTime");
    },

    createStatsLines(shift) {
      this.statsGraphLines.datasets[0].data = []
      this.statsGraphLines.labels = []

      this.statsLines = {};

      for (let index = 0; index < shift.length; index++) {
        // if (this.isDateNextMonth(doc.date.toDate())) {

        if (shift[index].shift.length == 0) {
          continue;
        }

        shift[index].shift.forEach((doc) => {
          if (doc.typ == 0) {
            var l = doc.line.split(", ");
            if (l.length > 1) {
              // console.log(doc.line, true);
              for (var i in l) {
                // console.log(l[i]);
                if (this.statsLines[l[i]] == null) {
                  this.statsLines[l[i]] = 0;
                }
                this.statsLines[l[i]]++;
              }
            } else {
              if (this.statsLines[doc.line] == null) {
                var l = doc.line;
                if (l.length == 0) {
                  l = shift[index].header_nr;
                }
                // console.log("l:" + l);
                this.statsLines[l] = 0;
              }
              this.statsLines[l]++;
            }
          }
        });
        // }
      }

      var sortable = [];
      for (var key in this.statsLines) {
        if (this.statsLines.hasOwnProperty(key)) {
          sortable.push([key, this.statsLines[key]]);
          this.statsGraphLines.labels.push(key);
          this.statsGraphLines.datasets[0].data.push(this.statsLines[key]);
        }
      }

      // sort items by value
      sortable.sort(function (a, b) {
        return b[1] - a[1]; // compare numbers
      });

      this.statsLines = sortable;
    },

    createStatsBreakLocations(shift) {

      this.statsGraphStartLocations.datasets[0].data = []
      this.statsGraphStartLocations.labels = []
      this.statsStartLocations = {};

      this.statsGraphEndLocations.datasets[0].data = []
      this.statsGraphEndLocations.labels = []
      this.statsEndLocations = {};


      this.statsGraphBreakLocations.datasets[0].data = []
      this.statsGraphBreakLocations.labels = []
      this.statsBreakLocations = {};

      shift.forEach((doc) => {
        // if (this.isDateNextMonth(doc.date.toDate())) {
        doc.shift.forEach((doc) => {
          if (doc.typ == 1) {
            doc.start_location = doc.start_location.replace("S - ", "");
            if (this.statsBreakLocations[doc.start_location] == null) {
              this.statsBreakLocations[doc.start_location] = 0;
            }
            this.statsBreakLocations[doc.start_location]++;
          }
        });

        // console.log("statsStartLocations")
        for(var index = 0; index < doc.shift.length; index++) {
          if (doc.shift[index].start_location != "" && doc.shift[index].typ == 0) {
            // console.log(doc.shift[index])
            if (this.statsStartLocations[doc.shift[index].start_location] == null) {
              this.statsStartLocations[doc.shift[index].start_location] = 0;
            }
            this.statsStartLocations[doc.shift[index].start_location]++;
            break;
          }
        }

        for(var index = doc.shift.length - 1; index > 0; index--) {
          if (doc.shift[index].end_location != "") {
            // console.log(doc.shift[index])
            if (this.statsEndLocations[doc.shift[index].end_location] == null) {
              this.statsEndLocations[doc.shift[index].end_location] = 0;
            }
            this.statsEndLocations[doc.shift[index].end_location]++;
            break;
          }
        }
        //}
      });

      var sortable = [];
      for (var key in this.statsBreakLocations) {
        if (this.statsBreakLocations.hasOwnProperty(key)) {
          sortable.push([key, this.statsBreakLocations[key]]);
          this.statsGraphBreakLocations.labels.push(key);
          this.statsGraphBreakLocations.datasets[0].data.push(this.statsBreakLocations[key]);
        }
          
      }

      sortable.sort(function (a, b) {
        return b[1] - a[1];
      });

      this.statsBreakLocations = sortable;



      var sortableB = [];
      for (var key in this.statsStartLocations) {
        if (this.statsStartLocations.hasOwnProperty(key)) {
          sortableB.push([key, this.statsStartLocations[key]]);
          this.statsGraphStartLocations.labels.push(key);
          this.statsGraphStartLocations.datasets[0].data.push(this.statsStartLocations[key]);
        }
          
      }

      sortableB.sort(function (a, b) {
        return b[1] - a[1];
      });

      this.statsStartLocations = sortableB;



      var sortableC = [];
      for (var key in this.statsEndLocations) {
        if (this.statsEndLocations.hasOwnProperty(key)) {
          sortableC.push([key, this.statsEndLocations[key]]);
          this.statsGraphEndLocations.labels.push(key);
          this.statsGraphEndLocations.datasets[0].data.push(this.statsEndLocations[key]);
        }
          
      }

      sortableC.sort(function (a, b) {
        return b[1] - a[1];
      });

      this.statsEndLocations = sortableC;
    },

    createStatsSick(shift) {
      this.statsSick = 0;

      this.statsGraphSick.datasets[0].data = []

      shift.forEach((doc) => {
        // if (this.isDateNextMonth(doc.date.toDate())) {
        if (doc.typ == 5) {
          this.statsSick++;
        }
        // }
      });

      this.statsGraphSick.datasets[0].data.push((shift.length - this.statsSick));
      this.statsGraphSick.datasets[0].data.push(this.statsSick);
       //= [(shift.length - this.statsSick).toString(), this.statsSick.toString()]
    },

    // compareStatServiceSituations( a, b ) {
    //   if ( a.count < b.count ){
    //     return -1;
    //   }
    //   if ( a.count > b.count ){
    //     return 1;
    //   }
    //   return 0;
    // },



    createStatServiceSituations(val) {
      this.statsGraphServiceSituations.datasets[0].data = []

      this.statsServiceSituations = [
        {
          text: '<i class="fas fa-dove" v-b-tooltip.hover title="Frühdienst"></i> Frühdienste',
          count: 0,
        },
        {
          text: '<i class="fas fa-sun" v-b-tooltip.hover title="Tagesdienst"></i> Tagesdienste',
          count: 0,
        },
        {
          text: '<i class="fas fa-cloud" v-b-tooltip.hover title="Mitteldienst"></i> Mitteldienste',
          count: 0,
        },
        {
          text: '<i class="fas fa-cloud-moon" v-b-tooltip.hover title="Spätdienst"></i> Spätdienste',
          count: 0,
        },
        {
          text: '<i class="fas fa-moon" v-b-tooltip.hover title="Nachtdienst"></i> Nachtdienste',
          count: 0,
        },
      ]

      this.statsServiceSituations[0].count = 0;
      this.statsServiceSituations[1].count = 0;
      this.statsServiceSituations[2].count = 0;
      this.statsServiceSituations[3].count = 0;
      this.statsServiceSituations[4].count = 0;

      for (let index = 0; index < val.length; index++) {
        if (val[index].time_start == null) {
          continue;
        }

        var h = val[index].time_start.split(":")[0];
        if (h < 7) {
          this.statsServiceSituations[0].count++;
        } else if (h < 12) {
          this.statsServiceSituations[1].count++;
        } else if (h < 15) {
          this.statsServiceSituations[2].count++;
        } else if (h < 20) {
          this.statsServiceSituations[3].count++;
        } else {
          this.statsServiceSituations[4].count++;
        }
      }

      this.statsGraphServiceSituations.datasets[0].data = [
        this.statsServiceSituations[0].count,
        this.statsServiceSituations[1].count,
        this.statsServiceSituations[2].count,
        this.statsServiceSituations[3].count,
        this.statsServiceSituations[4].count]

      this.statsServiceSituations = this.statsServiceSituations.sort(function (a, b) {
        return b.count - a.count; // compare numbers
      });

      // this.statsServiceSituations = this.statsServiceSituations.sort( compareStatServiceSituations );
    },
  },
  mounted() {
    if (this.shifts.length > 0) {
      this.createStatsLines(this.shifts);
      this.createStatsBreakLocations(this.shifts);
      this.createStatServiceSituations(this.shifts);
      this.createStatsSick(this.shifts);
      this.createStatsWorkingTime(this.shifts);
      this.createStatsTransitionalPeriods(this.shifts);
      this.componentKey += 1;  
    }
  },
  watch: {
    shifts: function (val) {
      // console.log("watch");
      this.isLoading = true
      if (val.length > 0) {
        this.createStatsLines(val);
        this.createStatsBreakLocations(val);
        this.createStatServiceSituations(val);
        this.createStatsSick(val);
        this.createStatsWorkingTime(val);
        this.createStatsTransitionalPeriods(val);
      }
      this.componentKey += 1;  
      this.isLoading = false
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      shifts: "getShifts",
      getFormatedDate: "getFormatedDate",
      getWeekdayForDate: "getWeekdayForDate",
      shiftViewerCalendar: "getShiftViewerCalendar",
      getMonthLabel: "getMonthLabel",
      getDaysInMonth: "getDaysInMonth"
    }),
  },
};
</script>

<style scoped>
  .flexbox {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-4 py-3;
  }

  .box {
    @apply rounded-lg sm:p-6 p-3;
  }

  .chart {
    @apply mx-auto py-5 max-h-96;
  }
</style>